/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Heading, Text, Image, SimpleGrid, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../components/Container';

import highlight2 from '../../images/highlights/monitor.svg';
import highlight3 from '../../images/highlights/engine.svg';
import highlightQuestion from '../../images/highlights/question.svg';
import whatsapp from '../../images/whatsapp-gray.svg';
import Form from '../../components/Form';

type HighlightProps = {
  icon: string;
  title: string;
  link?: string;
  toggleModal?: () => void;
  newTab?: boolean;
};

const Highlight = ({ icon, title, link, toggleModal, newTab }: HighlightProps) => {
  return (
    <Box textAlign="center" mb={16}>
      {link || toggleModal ? (
        <Link
          href={!toggleModal ? link : undefined}
          onClick={() => (toggleModal! ? toggleModal() : undefined)}
          target={newTab ? "_blank" : undefined}
        >
          <Image src={icon} alt={title} mb={6} marginX="auto" />
          <Text size={{ base: 'sm', lg: 'lg' }} fontWeight="500">
            {title}
          </Text>
        </Link>
      ) : (
        <Text size={{ base: 'sm', lg: 'lg' }} fontWeight="500">
          <Image src={icon} alt={title} mb={6} marginX="auto" />
          {title}
        </Text>
      )}
    </Box>
  );
};

const HelpCenter = () => {
  const { t } = useTranslation('helpCenter');
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
  const { formUrl } = data.site.siteMetadata;

  return (
    <Box id="help-center" px={{ base: 4, lg: 20 }} py={24} textAlign="center">
      <Container>
        <Box mb={24}>
          <Heading size="md" fontWeight="500" mb={6}>
            {t('title')}
          </Heading>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 4 }}>
          <Highlight
            icon={highlightQuestion}
            title={t('highlight1')}
            link="/faqs"
            newTab={false}
          />
          <Highlight
            icon={highlight2}
            title={t('highlight2')}
            link="https://youtube.com/playlist?list=PLYyPN35KiRi6Om2c78Ns_B6UAcv0FvzTO"
            newTab={true}
          />
          <Highlight
            icon={whatsapp}
            title={t('chat_with_us')}
            link="https://wa.me/5491125718626"
            newTab={true}
          />
          <Highlight
            icon={highlight3}
            title={t('highlight3')}
            toggleModal={toggleModal}
            newTab={true}
          />
        </SimpleGrid>
      </Container>
      <Form isOpen={modalOpen} onClose={toggleModal} formUrl={formUrl} />
    </Box>
  );
};

export default HelpCenter;
